import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Container from '../layout/Container'
import theme from '../../constants/theme'
import ResponsiveText from '../typography/ResponsiveText'
import useWindowResize from '../../helpers/useWindowResize'
import useIntersectionObserver from '../../helpers/useIntersectionObserver'

const Wrapper = styled.section`
  height: auto;
  min-height: ${props => props.noMinHeight? `unset` : `100vh`};
  margin-top: -96px;
  margin-bottom: 60px;

  .container {
    flex-wrap: wrap;
  }

  .header {
    display: flex;
    width: 100%;
    height: 100vh;
    z-index: 1;
    align-items: flex-end;
    padding-bottom: 90px;

    ${props => !props.isHomePage && `
      flex-wrap: wrap;
    `}

    .title {
      flex: 1 0 100%;

      ${props => props.isHomePage && `
        flex: 0;
        max-width: 50%;
      `}
    }

    .description {
      h2 {
        font-family: ${theme.fontNeue};
        text-transform: unset;
        font-weight: 500;
      }

      flex: 1;
      align-self: flex-end;
    }
  }

  .featured-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  ul.tags {
    margin-bottom: 30px;

    li {
      display: inline-block;
      font-size: 21px;
      background-color: ${theme.colorYellow};
      padding: 5px;
      text-transform: uppercase;
      margin-right: 25px;
    }
  }

  @media (max-width: 1024px) {
    height: ${props => props.featuredImage ? (props.noHeight?'':'1px'): ''};
    margin-top: -56px;
    margin-bottom: 30px;

    .header {
      padding-bottom: 40px;
      align-content: flex-end;
      height: 90vh;
    }

    .featured-image {
      width: 100vw;

      .gatsby-image-wrapper {
        height: 100%;
      }
    }

    ul.tags {
      white-space: normal;
      li {
        margin-top: 9px;
      }
      overflow: scroll;
    }
  }
`

export default function SectionHeroFullScreen({ title, description, featuredImage, noHeight, isHomePage, tags, noMinHeight = false, noScroll = false, isDark = false }) {
  const { width } = useWindowResize()
  const transformedTags = tags && tags.replace(/,/g, "  ■  ")

  const ref = useRef(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = entry && entry.isIntersecting

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]

    if (isVisible) {
      body.classList.add('dark-hero')
    }
    else {
      body.classList.remove('dark-hero')
    }
  }, [isVisible])

  return (
    <Wrapper isHomePage={isHomePage} noHeight={noHeight} noMinHeight={noMinHeight} featuredImage={featuredImage} ref={ref}>
      <Container>
        {featuredImage && <div className="featured-image hover-img">
            <GatsbyImage image={getImage(featuredImage.imageFile)} alt="" />
          </div>}
        <div className="header">
          <div className="title">
            <ResponsiveText type="bigger"><h1 style={{ color: isDark ? '#FAFAFA' : '#000A12'}}>{title === 'LloydsFarmacia' && width <= 480 ? 'Lloyds Farmacia' : title}</h1></ResponsiveText>
            {transformedTags !== '' && <p style={{ marginTop: '21px', fontSize: '12px', textTransform: 'uppercase', color: isDark ? '#FAFAFA' : '#000A12'}}>{transformedTags}</p>}
          </div>
          <div className="description">
            <ResponsiveText><h2>{description}</h2></ResponsiveText>
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}