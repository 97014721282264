import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/commons/Layout';
import styled from 'styled-components';

import SectionHeroFullScreen from '../components/sections/SectionHeroFullScreen';
import UnorderedList from '../components/commons/UnorderedList';

import ClientiSection from '../components/homepage/ClientiSection';
import CasiStudioSection from '../components/homepage/CasiStudioSection';
import Footer from '../components/commons/Footer';

import 'swiper/css';
import Accordion from '../components/commons/Accordion';
import InteractiveImageFounders from '../components/sections/InteractiveImageFounders';
import ResponsiveText from '../components/typography/ResponsiveText';

const ChiSiamoWrapper = styled.div``;

const ChiSiamoPage = ({ data }) => {
  const {
    backend: { clientis, casis, chiSiamoPage },
  } = data;

  return (
    <Layout>
      <ChiSiamoWrapper className="mx-auto" data-scroll-section>
        <SectionHeroFullScreen
          title={chiSiamoPage.Hero.title}
          featuredImage={chiSiamoPage.Hero.featuredImage}
          tags={undefined}
          isDark
        />
        <div className="container mx-auto">
          <ResponsiveText type="medium">
            <p className="mt-32 mb-12">{chiSiamoPage.Hero.bigText.anchor}</p>
          </ResponsiveText>
          <ResponsiveText type="normal">
            <p>{chiSiamoPage.Hero.bigText.content}</p>
          </ResponsiveText>
        </div>
        <ClientiSection clienti={clientis} />
        <UnorderedList
          title={chiSiamoPage.UnorderedList.title}
          items={chiSiamoPage.UnorderedList.UnorderedListElement}
        />
        <Accordion title={'I nostri team'} items={chiSiamoPage.Accordion} />
        <InteractiveImageFounders
          interactiveImage={chiSiamoPage.InteractiveImage}
        />
        <CasiStudioSection
          title={'Storie di\nsuccesso'}
          titleSize={'big'}
          subtitle={null}
          casi={casis}
          isHomePage
        />
        <Footer />
      </ChiSiamoWrapper>
    </Layout>
  );
};

export default ChiSiamoPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    backend {
      chiSiamoPage {
        InteractiveImage {
          background {
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            url
            id
          }
          InteractivePoint {
            image {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            detail
            positionX
            positionY
            subtitle
            title
          }
          title
        }
        Accordion {
          id
          image {
            id
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          points {
            point
            id
          }
          title
        }
        Hero {
          title
          bigText {
            content
            anchor
          }
          featuredImage {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        UnorderedList {
          title
          UnorderedListElement {
            id
            description
            subtitle
            title
          }
        }
        Accordion {
          id
          title
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          points {
            id
            point
          }
        }
      }
      clientis(sort: "ordine:asc") {
        titolo
        immagine {
          url
        }
        ordine
      }
      casis(where: { inSlider: true }) {
        slug
        titolo
        tags
        immagineCard {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 560, placeholder: BLURRED, quality: 100)
            }
          }
        }
        inSlider
      }
      seoRules(where: { slug_eq: "/casi-studio/" }) {
        slug
        seo {
          title
          description
          keywords
        }
      }
    }
  }
`;
