import React from "react";
import styled from "styled-components";
import theme from "../../constants/theme";
import ResponsiveText from "../typography/ResponsiveText";
import DownB from "../../images/down-b.svg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AccordionWrapper = styled.div`
  background-color: ${theme.colorWhite};
  color: ${theme.colorBlack};
  width: 100%;
  padding-bottom: 75px;

  h2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 480px) {
    h2 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .accordion-container {
    .accordion-item {
      border-bottom: 2px solid black;

      .accordion-item-header {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;

        .arrow {
          margin: auto 0;
          .accordion-arrow {
            height: 40px;
          }

          @media (max-width: 1024px) {
            .accordion-arrow {
              height: 20px;
            }
          }
          .rotate180 {
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }

      .accordion-item-body {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-bottom: 30px;
        padding: 20px 0px;

        .accordion-item-bullet-list {
          margin: auto 0;
        }

        .image-container {
          width: 100%;

          .team-img {
            margin: 20px auto;
          }
        }

        @media (min-width: 1024px) {
          .image-container {
            position: relative;
            width: 100%;
          }
        }

        .accordion-item-point {
          display: block;
          margin-bottom: 20px;

          &:before {
            content: "";
            background-color: ${theme.colorYellow};
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
      }

      @media (max-width: 1024px) {
        .accordion-item-body {
          grid-template-columns: 100%;
          padding: 0;
        }
      }
    }
  }
`;

function Accordion({ title, items }) {
  const [openCategoryId, setOpenCategoryId] = React.useState();

  const isOpen = (id) => {
    return openCategoryId === id;
  };
  return (
    <AccordionWrapper data-scroll-section>
      <div className="container mx-auto">
        <ResponsiveText type="bigger">
          <h2>{title}</h2>
        </ResponsiveText>
        <div className="accordion-container">
          {items.map((item) => {
            return (
              <div className="accordion-item" key={item.id}>
                <div
                  className="accordion-item-header"
                  onClick={() => {
                    isOpen(item.id)
                      ? setOpenCategoryId()
                      : setOpenCategoryId(item.id);
                  }}
                >
                  <ResponsiveText type="medium">
                    <h3>{item.title}</h3>
                  </ResponsiveText>
                  <div className="arrow">
                    <img
                      src={DownB}
                      alt="down"
                      className={
                        isOpen(item.id)
                          ? "accordion-arrow rotate180"
                          : "accordion-arrow"
                      }
                    />
                  </div>
                </div>
                {isOpen(item.id) && (
                  <div className="accordion-item-body">
                    <div className="accordion-item-bullet-list">
                      {item.points.map((point) => {
                        return (
                          <ResponsiveText type="normal" key={point.id}>
                            <span className="accordion-item-point">
                              {point.point}
                            </span>
                          </ResponsiveText>
                        );
                      })}
                    </div>
                    {item.image && (
                      <div className="image-container">
                        {item.image.url}
                        <GatsbyImage
                          imgClassName="team-img"
                          image={getImage(item.image[0].imageFile)}
                          alt={item.title}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </AccordionWrapper>
  );
}

export default Accordion;
