import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import arrowleft from '../../../static/arrow-left.svg';
import arrowright from '../../../static/arrow-right.svg';

const TecnologieWrapper = styled.section`
  height: 100%;
  /* margin-top: -40vh; */
  margin-top: 120px;
  margin-bottom: 120px;
  @media (max-width: 1024px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .tecnologie-wrapper {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .tecnologie-content {
      width: 100%;

      .tecnologie-title {
        font-size: 30px;
        text-align: center;
        margin-bottom: 80px;
      }

      .tecnologie-slider {
        .swiper {
          .swiper-wrapper {
            .swiper-slide {
              .mx-auto {
                img {
                  margin: 0 auto;
                }
              }
            }
          }
        }
        .navigation {
          position: relative;
          height: 50px;
          bottom: 40px;
          .swiper-nav-button {
            position: absolute;
            z-index: 99999;
            &.prev {
              left: 0;
            }
            &.next {
              right: 0;
            }

            &.hidden-button {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
`;

export default function TecnologieSection({
  anchor,
  clienti,
  generatedId,
  fromPage,
}) {
  const [swiper, setSwiper] = useState(null);
  const [swiperNav, setSwiperNav] = useState({
    isBeginning: true,
    isEnd: false,
  });

  useEffect(() => {
    if (swiper) {
      setSwiperNav({
        isBeginning: swiper.isBeginning,
        isEnd: swiper.isEnd,
      });
    }
  }, [swiper]);

  const sliderSettings = {
    modules: [Navigation],
    height: 37,
    slidesPerView: 5,
    spaceBetween: 0,
    slideToClickedSlide: false,
    centerInsufficientSlides: true,
    onSwiper: (swiper) => setSwiper(swiper),
    onSlideChange: (swiper) => {
      setSwiperNav({
        ...swiperNav,
        isBeginning: swiper.isBeginning,
        isEnd: swiper.isEnd,
      });
    },
    navigation: {
      nextEl: '.swiper-nav-button.next',
      prevEl: '.swiper-nav-button.prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 5,
      },
      0: {
        slidesPerView: 2,
      },
    },
  };

  return (
    <TecnologieWrapper
      data-scroll-section
      data-scroll
      data-scroll-id={
        (fromPage ? 'clientiPageSection_' : 'clientiSection_') + generatedId
      }
      id={anchor}
    >
      <div className="container mx-auto">
        <div className="">
          <div className="tecnologie-wrapper">
            <div className="tecnologie-content">
              <h3 className="tecnologie-title">
                {fromPage ? 'Our customers' : 'Siamo al fianco di'}
              </h3>
              <div className="tecnologie-slider">
                <Swiper {...sliderSettings}>
                  {clienti.map((t, i) => (
                    <SwiperSlide key={i}>
                      <div className="mx-auto hover-img">
                        <img
                          className=""
                          src={theme.backendUrl + t.immagine.url}
                          width="136px"
                          alt={t.titolo}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                  <div className="navigation">
                    <div
                      className={`swiper-nav-button prev ${
                        swiperNav.isBeginning ? 'hidden-button' : ''
                      }`}
                    >
                      <img src={arrowleft} />
                    </div>
                    <div
                      className={`swiper-nav-button next ${
                        swiperNav.isEnd ? 'hidden-button' : ''
                      }`}
                    >
                      <img src={arrowright} />
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TecnologieWrapper>
  );
}
