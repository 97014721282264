import React from "react";

const Icon = ({ type, ...rest}) => {
    switch (type) {
      case "arrowLeft":
        return (

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
          >
            <circle
              cx="12.5"
              cy="12.5"
              r="12.5"
              fill="#000A12"
              transform="rotate(-180 12.5 12.5)"
            ></circle>
            <path
              fill="#FAFAFA"
              d="M15.77 5.924l-.994-.994-7.951 7.952 7.951 7.951.994-.994-6.257-6.257-.591-.7.591-.7 6.257-6.258z"
            ></path>
          </svg>
        )
        
      case "arrowRight":
        return (

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
          >
            <circle cx="12.5" cy="12.5" r="12.5" fill="#000A12"></circle>
            <path
              fill="#FAFAFA"
              d="M9.23 19.076l.994.994 7.951-7.952-7.951-7.951-.994.994 6.257 6.257.591.7-.591.7-6.257 6.258z"
            ></path>
          </svg>
        )
      // default:
      //   return null;
    }

  return null
};

export default Icon