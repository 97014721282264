import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  @media (max-width: 1024px) {
    max-width: 100vw;
    margin-bottom: 60px;
  }
`;

export default function Container({ children, marginBottom = "60px" }) {
  StyledContainer.componentStyle.rules[0] = `@media (max-width:1024px){max-width:100vw;margin-bottom:${marginBottom};}`;
  return (
    <StyledContainer className="container mx-auto h-full flex">
      {children}
    </StyledContainer>
  );
}
