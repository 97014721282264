import React from "react";
import styled from "styled-components";
import theme from "../../constants/theme";
import ResponsiveText from "../typography/ResponsiveText";

const UnorderedListWrapper = styled.div`
  background-color: ${theme.colorBlack};
  color: ${theme.colorWhite};
  width: 100%;
  padding-bottom: 75px;

  h2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 480px) {
    h2 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .list-grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media (max-width: 1024px) {
      grid-template-columns: 100%;
    }

    .list-grid-item {
      display: grid;
      grid-template-columns: 10% 90%;
      grid-column-gap: 0;
      grid-row-gap: 0;

      .list-grid-item-dot {
        height: 100%;

        span {
          display: table;
          margin: 14px 0;
          background-color: ${theme.colorYellow};
          content: "";
          height: 20px;
          width: 20px;
        }
      }

      .list-grid-item-content {
        .list-grid-item-title {
          font-size: 31px;
          font-weight: 600;
          font-family: ${theme.fontNeue};
        }

        .list-grid-item-subtitle {
          color: ${theme.colorYellow};

          &:after,
          &:before {
            content: "/";
          }
        }

        .list-grid-item-description {
          padding-right: 50px
        }
        @media (max-width: 480px) {
          .list-grid-item-description {
            padding-right: 20px
          }
        }
      }
    }
  }
`;

function UnorderedList({ title, items }) {
  return (
    <UnorderedListWrapper data-scroll-section>
      <div className="container mx-auto">
        <ResponsiveText type="bigger">
          <h2>{title}</h2>
        </ResponsiveText>
        <div className="list-grid-container">
          {items.map((item, index) => {
            return (
              <div className="list-grid-item" key={index}>
                <div className="list-grid-item-dot">
                  <span></span>
                </div>
                <div className="list-grid-item-content">
                  <div
                    className="list-grid-item-title"
                  >
                    {item.title}
                  </div>
                  {item.subtitle && (
                    <span className="list-grid-item-subtitle">
                      {item.subtitle}
                    </span>
                  )}
                  <div className="list-grid-item-description">{item.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </UnorderedListWrapper>
  );
}

export default UnorderedList;
