import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import CasiCard from '../cases/CasiCard';
import Container from '../layout/Container';
import ResponsiveText from '../typography/ResponsiveText';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import 'swiper/css';

import Icon from '../commons/Icon';

import arrowleft from '../../../static/arrow-left.svg';
import arrowright from '../../../static/arrow-right.svg';

import useWindowSize from '../../helpers/useWindowResize';

const StyledWrapper = styled.section`
  margin-top: 120px;
  margin-bottom: 120px;
  overflow: hidden;

  .casiStudioSection__wrapper {
    width: 100%;

    .casiStudioSection__intro {
      margin-bottom: 40px;
    }

    .casiStudioSection__cards {
      .swiper {
        overflow: visible;
        position: relative;

        .navigation {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 100;
          display: flex;
          justify-content: space-between;
          width: 100%;

          .swiper-nav-button {
            svg {
              width: 50px;
              height: 50px;
            }

            &.hidden-button {
              visibility: hidden;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    margin-top: 60px;
    margin-bottom: 60px;
    min-height: 530px;
    .casiStudioSection__wrapper {
      .casiStudioSection__intro {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 24px;
      }
      .casiCard-wrapper {
        h3 {
          font-size: 25px !important;
          line-height: 28px !important;
        }
      }
    }
  }
`;

export default function CasiStudioSection({
  casi,
  isHomePage,
  title = 'casi\nstudio',
  titleSize = 'extreme',
  subtitle = 'Esplora i prodotti digitali e i servizi che abbiamo progettato',
}) {
  const { width } = useWindowSize();
  const [swiper, setSwiper] = useState(null);
  const [swiperNav, setSwiperNav] = useState({
    isBeginning: true,
    isEnd: false,
  });

  useEffect(() => {
    if (swiper) {
      setSwiperNav({
        isBeginning: swiper.isBeginning,
        isEnd: swiper.isEnd,
      });
    }
  }, [swiper]);

  const swiperSettings = {
    modules: [Navigation],
    slidesPerView: 2.5,
    spaceBetween: 40,
    onSwiper: (swiper) => setSwiper(swiper),
    onSlideChange: (swiper) => {
      setSwiperNav({
        ...swiperNav,
        isBeginning: swiper.isBeginning,
        isEnd: swiper.isEnd,
      });
    },
    navigation: {
      nextEl: '.swiper-nav-button.next',
      prevEl: '.swiper-nav-button.prev',
    },
    breakpoints: {
      0: {
        slidesPerView: 1.5,
      },
      768: {
        slidesPerView: 2.5,
      },
    },
  };

  return (
    <StyledWrapper>
      <Container>
        <div className="casiStudioSection__wrapper">
          <div className="casiStudioSection__intro">
            <ResponsiveText type={titleSize}>
              <h2>
                {title.split('\n')[0]} <br /> {title.split('\n')[1]}
              </h2>
            </ResponsiveText>
            {subtitle && (
              <ResponsiveText>
                <p>
                  {subtitle.split('\n')[0]} <br /> {subtitle.split('\n')[1]}
                </p>
              </ResponsiveText>
            )}
          </div>
          <div className="casiStudioSection__cards">
            <Swiper {...swiperSettings}>
              {casi.map((c, i) => (
                <SwiperSlide key={i}>
                  <CasiCard card={c} />
                </SwiperSlide>
              ))}
              <div className="navigation">
                <div
                  className={`swiper-nav-button prev ${
                    swiperNav.isBeginning ? 'hidden-button' : ''
                  }`}
                >
                  {/* <img src={arrowleft} /> */}
                  <Icon type="arrowLeft" />
                </div>
                <div
                  className={`swiper-nav-button next ${
                    swiperNav.isEnd ? 'hidden-button' : ''
                  }`}
                >
                  {/* <img src={arrowright} /> */}
                  <Icon type="arrowRight" />
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </Container>
    </StyledWrapper>
  );
}
