import React from "react";
import styled from "styled-components";
import theme from "../../constants/theme";
import ResponsiveText from "../typography/ResponsiveText";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CloseIcon from "../../images/close-icon.svg";

import useWindowSize from '../../helpers/useWindowResize';

const InteractiveImageFoundersWrapper = styled.div`
  background-color: ${theme.colorWhite};
  color: ${theme.colorBlack};
  width: 100%;
  padding-bottom: 75px;

  h2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 480px) {
    h2 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .interactive-image {
    position: relative;
    width: 100%;

    .interactive-image-point {
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: ${theme.colorYellow};
      width: 20px;
      height: 20px;
      transform: translateX(-50%) translateY(-50%);
      transition: all 0.3s ease;

      div {
        visibility: hidden;
      }

      .interactive-image-modal {
        position: relative;

        .interactive-image-modal-desc {
          background-color: ${theme.colorBlack};
          color: ${theme.colorWhite};
          padding: 10px;

          span {
            display: block;
          }

          .interactive-image-modal-desc-role {
            font-family: "${theme.fontSyne}";
            font-weight: 800;
            font-size: 18px;
            line-height: 1.2;
            margin-bottom: 7px;
          }
          .interactive-image-modal-desc-name {
            font-size: 18px;
            margin-bottom: 10px;
          }
          .interactive-image-modal-desc-detail {
            text-decoration: underline;
          }
        }
      }
    }

    @media (min-width: 480px) {
      .interactive-image-point {
        &:hover {
          background: transparent;
          width: 21%;
          height: auto;
          border: 5px solid ${theme.colorYellow};

          div {
            visibility: visible;
          }
        }
      }
    }
  }

  .modal-figure {
    background-color: #000000bb;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 2147483647;
    top: 0;
    color: white;

    .modal-figure-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      .modal-figure-close-button {
        width: 100%;
        text-align: right;
        font-family: "${theme.fontSyne}";
        text-transform: uppercase;
        line-height: 1.2;
        font-weight: 700;
        padding: 5px 0;

        img {
          vertical-align: middle;
          margin-left: 5px;
          display: inline;
        }
      }

      .modal-figure-card {
        border: 5px solid ${theme.colorYellow};

        .modal-figure-card-img {
          width: 100%;
        }
        .modal-figure-card-desc {
          background-color: ${theme.colorBlack};
          color: ${theme.colorWhite};
          padding: 10px;

          span {
            display: block;
            margin-bottom: 7px;
          }

          .modal-figure-card-desc-role {
            font-family: "${theme.fontSyne}";
            font-weight: 800;
            font-size: 18px;
            line-height: 1.2;
          }
          .modal-figure-card-desc-name {
            font-size: 18px;
          }
          .modal-figure-card-desc-detail {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

function InteractiveImageFounders({ interactiveImage }) {
  const { width } = useWindowSize();

  const [openedFigure, setOpenedFigure] = React.useState();

  return (
    <InteractiveImageFoundersWrapper data-scroll-section>
      {/* MODAL (only visible when vw < 480px) */}
      {openedFigure && (
        <div className="modal-figure">
          <div className="modal-figure-center">
            <button
              className="modal-figure-close-button"
              onClick={() => setOpenedFigure(undefined)}
            >
              chiudi <img src={CloseIcon} alt="close" />
            </button>
            <div className="modal-figure-card">
              <GatsbyImage
                imgClassName="modal-figure-card-img"
                image={getImage(openedFigure.image.imageFile)}
                alt={interactiveImage.title}
              />
              <div className="modal-figure-card-desc">
                <span className="modal-figure-card-desc-role">
                  {openedFigure.subtitle}
                </span>
                <span className="modal-figure-card-desc-name">
                  {openedFigure.title}
                </span>
                <span className="modal-figure-card-desc-detail">
                  <a target="_blank" rel="noreferrer" href={openedFigure.detail}>LinkedIn</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container mx-auto">
        <ResponsiveText type="bigger">
          <h2>{interactiveImage.title}</h2>
        </ResponsiveText>
        <div className="interactive-image">
          <GatsbyImage
            image={getImage(interactiveImage.background.imageFile)}
            alt={interactiveImage.title}
          />

          {interactiveImage.InteractivePoint &&
            interactiveImage.InteractivePoint.map((point, index) => (
              <div
                className="interactive-image-point"
                key={index}
                style={{ left: point.positionX, top: point.positionY }}
                onClick={() => {
                  if (width < 480) {
                    setOpenedFigure(point);
                  }
                }}
              >
                <div className="interactive-image-modal">
                  <GatsbyImage
                    image={getImage(point.image.imageFile)}
                    alt={interactiveImage.title}
                  />
                  <div className="interactive-image-modal-desc">
                    <span className="interactive-image-modal-desc-role">
                      {point.subtitle}
                    </span>
                    <span className="interactive-image-modal-desc-name">
                      {point.title}
                    </span>
                    <span className="interactive-image-modal-desc-detail">
                      <a target="_blank" rel="noreferrer" href={point.detail}>LinkedIn</a>
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </InteractiveImageFoundersWrapper>
  );
}

export default InteractiveImageFounders;
